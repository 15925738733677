@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  background-color: #e3f2fd; /* 淡藍天空色 */
}

.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  animation: float 6s ease-in-out infinite;
}

/* 新增 Z 字樣效果 */
.circle::before {
  content: "Z";
  position: absolute;
  color: rgba(0, 0, 0, 0.1);
  font-size: 3rem;
  font-weight: bold;
  opacity: 0;
  animation: fadeInOut 6s ease-in-out infinite;
}

.circle:nth-child(1) {
  width: 200px;
  height: 200px;
  background-color: #ffb74d; /* 橘黃色 */
  top: 20%;
  left: 15%;
  animation-duration: 8s;
}

.circle:nth-child(1)::before {
  animation-delay: 2s; /* 延遲 Z 字浮現 */
}

.circle:nth-child(2) {
  width: 150px;
  height: 150px;
  background-color: #ffd54f; /* 橘黃色（較亮） */
  top: 40%;
  left: 70%;
  animation-duration: 7s;
  animation-delay: 1s;
}

.circle:nth-child(2)::before {
  animation-delay: 3s; /* 延遲 Z 字浮現 */
}

.circle:nth-child(3) {
  width: 100px;
  height: 100px;
  background-color: #64b5f6; /* 淡藍色 */
  top: 70%;
  left: 50%;
  animation-duration: 9s;
  animation-delay: 2s;
}

.circle:nth-child(3)::before {
  animation-delay: 4s; /* 延遲 Z 字浮現 */
}

.circle:nth-child(4) {
  width: 250px;
  height: 250px;
  background-color: #4fc3f7; /* 淡藍色（較亮） */
  top: 10%;
  left: 80%;
  animation-duration: 10s;
  animation-delay: 3s;
}

.circle:nth-child(4)::before {
  animation-delay: 5s; /* 延遲 Z 字浮現 */
}

.circle:nth-child(5) {
  width: 120px;
  height: 120px;
  background-color: #ff8a65; /* 橘黃色（較深） */
  top: 80%;
  left: 25%;
  animation-duration: 5s;
  animation-delay: 4s;
}

.circle:nth-child(5)::before {
  animation-delay: 6s; /* 延遲 Z 字浮現 */
}
