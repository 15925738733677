@keyframes float {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 0.7;
  }
}

.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: -1;
  background: linear-gradient(to bottom right, #e3f2fd, #bbdefb); /* 漸層背景 */
}

.circle {
  position: absolute;
  border-radius: 50%;
  opacity: 0.4;
  animation: float 8s ease-in-out infinite;
}

/* Z 字樣效果 */
.circle::before {
  content: "Z";
  position: absolute;
  color: rgba(0, 0, 0, 0.08);
  font-size: 3rem;
  font-weight: bold;
  opacity: 0;
  animation: fadeInOut 8s ease-in-out infinite;
}

/* 圓圈樣式與位置 */
.circle:nth-child(1) {
  width: 220px;
  height: 220px;
  background-color: #ff8a80; /* 淡紅色 */
  top: 15%;
  left: 10%;
  animation-duration: 10s;
}

.circle:nth-child(1)::before {
  animation-delay: 2s;
}

.circle:nth-child(2) {
  width: 180px;
  height: 180px;
  background-color: #81c784; /* 淡綠色 */
  top: 50%;
  left: 75%;
  animation-duration: 12s;
}

.circle:nth-child(2)::before {
  animation-delay: 4s;
}

.circle:nth-child(3) {
  width: 140px;
  height: 140px;
  background-color: #64b5f6; /* 淡藍色 */
  top: 80%;
  left: 30%;
  animation-duration: 9s;
}

.circle:nth-child(3)::before {
  animation-delay: 3s;
}

.circle:nth-child(4) {
  width: 260px;
  height: 260px;
  background-color: #ffcc80; /* 淡橘色 */
  top: 10%;
  left: 85%;
  animation-duration: 14s;
}

.circle:nth-child(4)::before {
  animation-delay: 5s;
}

.circle:nth-child(5) {
  width: 160px;
  height: 160px;
  background-color: #ba68c8; /* 淡紫色 */
  top: 70%;
  left: 50%;
  animation-duration: 11s;
}

.circle:nth-child(5)::before {
  animation-delay: 6s;
}
